export default [
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/agreement/AgreementIndex.vue'),
    meta: {
      pageTitle: 'Agreements',
      breadcrumb: [
        {
          text: 'Agreements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agreement-beta',
    name: 'agreement-beta',
    component: () => import('@/views/agreement/AgreementIndexBeta.vue'),
    meta: {
      pageTitle: 'Agreements',
      breadcrumb: [
        {
          text: 'Agreements',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract-consent',
    name: 'contract-consent',
    component: () => import('@/views/contract-consent/ContractConsentIndex.vue'),
    meta: {
      pageTitle: 'Contract consent',
      breadcrumb: [
        {
          text: 'Contract consent',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contract-consent-beta',
    name: 'contract-consent-beta',
    component: () => import('@/views/contract-consent/ContractConsentBetaIndex.vue'),
    meta: {
      pageTitle: 'Contract consent',
      breadcrumb: [
        {
          text: 'Contract consent',
          active: true,
        },
      ],
    },
  },
]
