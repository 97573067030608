export default [
  {
    path: '/discounts',
    name: 'discounts',
    component: () => import('@/views/discount/DiscountIndex.vue'),
    meta: {
      pageTitle: 'Discounts',
      breadcrumb: [
        {
          text: 'Discounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/discounts-beta',
    name: 'discounts-beta',
    component: () => import('@/views/discount/DiscountGqlIndex.vue'),
    meta: {
      pageTitle: 'Discounts',
      breadcrumb: [
        {
          text: 'Discounts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/discounts/:id',
    name: 'discount-detail',
    component: () => import('@/views/discount/detail/DiscountDetail.vue'),
    meta: {
      pageTitle: 'Discount',
      breadcrumb: [
        {
          text: 'Discounts',
          active: false,
          to: { name: 'discounts-beta' },
        },
        {
          text: 'Discount detail',
          active: true,
        },
      ],
    },
  },
]
